import React from "react";
import * as PropTypes from "prop-types";
import './taModalSteps.css'

export const TaModalSteps = (props) => {
    return (
        <div className={'ta-modal-steps'}>
            {props.steps.map((label, index) => (
                <div key={index} className={'ta-modal-step' + (props.activeStep === index+1 ? ' is-active' : '')}>
                    <div className={'ta-modal-step__no'}>{index+1}</div>
                    <div className={'ta-modal-step__label'}>{label}</div>
                </div>
            ))}
        </div>
    )
}
TaModalSteps.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeStep: PropTypes.number.isRequired,
};