import React from "react";
import './TaToolbar.css';

export const TaToolbarSpace = (props) => {

    let classNames = ['ta-toolbar__space'];
    if (props.className) {
        classNames.push(props.className);
    }
    return (
        <div className={classNames.join(' ')} />
    )
};
