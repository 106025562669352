import React from "react";
import PropTypes from 'prop-types';
import "./style.css";

import {TaIcon} from "components/taUi/taIcon";

export class TaInputDate extends React.PureComponent {

    static propTypes = {
        name: PropTypes.string.isRequired,
        value:  PropTypes.string,
        min:  PropTypes.string,
        max:  PropTypes.string,
        iconBefore: PropTypes.string,
        iconAfter: PropTypes.string,
        onEvent: PropTypes.func,
        invalid: PropTypes.bool,
        clear: PropTypes.bool,
        isActive: PropTypes.bool,
        autofocus: PropTypes.bool,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        value: '',
    };

    constructor(props) {
        super(props);
        this.inputRef = null;
        this.setInputRef = element => {
            this.inputRef = element;
        };
        this.state = {
            hasFocus: false,
            value: ''
        };
    };

    componentDidMount() {
        if (
            this.props.value
        ) {
            this.setState({
                value: this.props.value
            });
        }
        if (
            this.props.autofocus &&
            this.inputRef
        ) {
            this.inputRef.focus();
        }
    }

    onInputFocus = (event) => {
        this.setState({
            hasFocus: true
        });
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    onInputBlur = (event) => {
        this.setState({
            hasFocus: false
        });
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    onInputChange = (event) => {
        this.updateStateValue(event.target.value);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    onInputPaste = (event) => {
        this.emitEvent('paste', event.clipboardData.getData('text'));
    };


    onKeyUp = (event) => {
        if (event.key === 'Enter') {
            this.emitEvent('enter');
            // } else if (event.key === 'Escape') {
            //   this.updateStateValue('', 'escape');
        }
    };

    clearInput = () => {
        this.updateStateValue('');
    };

    updateStateValue = (value, eventName) => {
        this.setState(
            {
                value: value
            },
            () => this.emitEvent(eventName || 'change')
        );
    };

    emitEvent = (eventName, value) => {
        const props = this.props;
        if (props.onEvent) {
            props.onEvent({
                name: eventName,
                data: {
                    property: props.name,
                    value: (typeof value !== 'undefined') ? value : this.state.value
                }
            })
        }
    };

    render() {

        const props = this.props;
        const state = this.state;
        const inputId = props.id;
        let baseClass = 'ta-input';

        let classNames = [baseClass, baseClass + '--date'];
        if (props.invalid) {
            classNames.push(baseClass + '--invalid')
        }
        if (props.isActive && !props.readOnly) {
            classNames.push(baseClass + '--active')
        }
        if (state.hasFocus && !props.readOnly) {
            classNames.push(baseClass + '--focus')
        }
        if (!state.value) {
            classNames.push(baseClass + '--empty')
        }
        if (props.readOnly) {
            classNames.push(baseClass + '--readonly')
        }

        if (props.className) {
            classNames.push(props.className)
        }

        return (
            <label id={inputId} className={classNames.join(' ')}>
                {(props.iconBefore) && <div className={'ta-input__icon'}><TaIcon name={props.iconBefore}/></div>}
                {(props.clear) &&
                    <div className="ta-input__button" onClick={this.clearInput}><TaIcon name="clear"/></div>}
                {(props.iconAfter) && <div className="ta-input__icon"><TaIcon name={props.iconAfter}/></div>}
                <input
                    type="date"
                    name={props.name}
                    value={this.state.value}
                    min={props.min}
                    max={props.max}
                    onPaste={this.onInputPaste}
                    onChange={this.onInputChange}
                    onKeyUp={this.onKeyUp}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    ref={this.setInputRef}
                    readOnly={props.readOnly}
                    lang="en-US"
                />
            </label>
        )
    }
}
