import React from 'react';
import * as PropTypes from "prop-types";
import {
    TaModal,
    TaModalContent,
    TaModalContentMargin,
    TaModalFooter,
    TaModalHeader
} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaInputDate} from "../../taUi/taInputDate";
import {TaFormField} from "../../taUi/taFormField/taFormField";
import MHTApi from "services/MHTApi";
import {TaFormMessages} from "../../taUi/taFormMessage/taFormMessage";

class TaMhtEditorReCommitTmModal extends React.Component {

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        mhtId: PropTypes.string.isRequired,
        onCancel: PropTypes.func.isRequired,
        onDone: PropTypes.func.isRequired,
    };

    render() {

        const {
            isOpen,
            onCancel,
            ...props
        } = this.props;

        return (
            <TaModal
                isOpen={isOpen}
                onCancel={() => onCancel()}
            >
                {(!!isOpen) && (
                    <TaMhtEditorReCommitTmModalContent {...props} onCancel={() => onCancel()}/>
                )}
            </TaModal>
        );
    }
}

function todayDate() {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

class TaMhtEditorReCommitTmModalContent extends React.Component {

    static propTypes = {
        mhtId: PropTypes.string.isRequired,
        onCancel: PropTypes.func.isRequired,
        onDone: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: todayDate(),
            error: null,
            isLoading: false
        };
    }

    onSubmit = () => {
        this.setState({
            isLoading: true,
            error: null,
        });
        MHTApi.reCommit(this.props.mhtId,{
            register_date: this.state.value
        }).then(response => {
            this.setState({
                isLoading: false,
            });
            this.props.onDone();
        }).catch(error => {
            console.log(error);
            this.setState({
                isLoading: false,
                error: 'error',
            });
        })
    }
    onChange = (event) => {
        this.setState({
            value: event.target.value
        });
    }

    render() {


        return (
            <React.Fragment>
                <TaModalHeader
                    onCancel={() => this.props.onCancel()}
                    label={'Re-Commit TM'}
                    warning={true}
                />
                <TaModalContent>
                    <TaModalContentMargin>
                        <p>
                            <b>Are you sure want to Re-commit Translation Memory?</b><br/>
                            Please also check the register date below.
                        </p>
                        <TaFormField
                            label={'Register Date'}
                            isRequired={true}
                        >
                        <TaInputDate
                            name="date"
                            value={this.state.value}
                            onChange={this.onChange}
                            required={true}
                        />
                        </TaFormField>
                    </TaModalContentMargin>
                </TaModalContent>
                {this.state.error ?
                    (
                        <TaFormMessages messages={[
                            {
                                type: 'error',
                                message: 'There was an error while re-commiting TM. Please try again.',
                            }
                        ]}/>
                    ) :null}
                <TaModalFooter>
                    <TaButton
                        type="reset"
                        icon={'clear'}
                        label={'Cancel'}
                        onClick={() => this.props.onCancel()}
                    />
                    <div/>
                    <TaButton
                        type="submit"
                        icon={'check'}
                        iconAlign={'right'}
                        label={this.state.isLoading?'Processing':'Proceed'}
                        isLoading={this.state.isLoading}
                        onClick={() => this.onSubmit()}
                    />
                </TaModalFooter>
            </React.Fragment>
        );
    }
}

export default TaMhtEditorReCommitTmModal;
