import React from 'react';
import {bindActionCreators} from "redux";
import {hide, show} from "redux-modal";
import {stopSubmit, reset} from 'redux-form'
import {connect} from "react-redux";
import {merge} from 'actions/entities';
import {
  TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL,
  TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL,
  TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL, TRANSLATION_MEMORY_DETAIL_IMPORT_FORM,
  TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL
} from "misc/constants";
import {TaPane} from "components/taUi/taPane/taPane";
import TranslationMemoryDetail from "./translationMemoryDetail";
import MemoryApi from "services/MemoryApi";
import {exportExcelRequest, exportTmxRequest, searchClearRequest, searchUpdateRequest} from "actions/memories";
import {getCurrentTranslationMemoryDetail, getSelectedTranslationMemoryItemNormalized} from "selectors/lists";
import {setLoading} from "actions/loading";
import {normalize} from "normalizr";
import {camelizeKeys} from 'humps';
import {memorySchema} from "schemas";
import TranslationMemoryBatchDeleteModal from "./translationMemoryBatchDeleteModal";

class TranslationMemoryDetailContainer extends React.Component {

  static defaultProps = {
    wordList: null
  };

  constructor() {

    super();
    this.defaultSearch = {
      string: '',
      isValid: false
    };

    this.state = {
      updated: 0,
      page: 1,
      totalItemCount: 0,
      items: [],
      isLoading: true,
      isSaving: false,
      modalBatchDeleteIsOpen: false,
      search: {
        ...this.defaultSearch
      },
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems = (page, string) => {

    this.setState({
      isLoading: true
    }, () => {
      return MemoryApi
        .searchItemList(this.props.params.id, page, string)
        .then(response => {
          const normalized = normalize(camelizeKeys(response.data.results), [memorySchema]);
          delete normalized["entities"]["memories"]
          this.props.merge(normalized.entities);
          let newState = {
            updated: Date.now(),
            items: response.data.results,
            totalItemCount: response.data.count,
            isLoading: false,
            isSaving: false
          };
          if (page) {
            newState.page = page;
          }
          this.setState(newState);
        })
        .catch(error => {
          console.error('loadItems - error', error);
        });
    });

  };

  triggerModal = (name, payload) => {
    console.log("triggerModal", name, payload);
    const props = this.props;
    const memory = props.memory;
    const service = memory ? memory.service :null;

    const memoryDenormalized = props.memoryDenormalized;

    switch (name) {
      case 'delete':
        // delete translation memory itself
        break;
      case 'attrMany':
      case 'attrAll':
        // bulk edit items' attributes

        const items = this.state.items;
        const keys = ['clients', 'jobs'];
        let initialValues = {};

        if (name === 'attrAll') {
          keys.forEach(key => {
            if (memoryDenormalized[key]) {
              initialValues[key] = memoryDenormalized[key];
            }
          });
        } else {


          keys.forEach(key => {
            initialValues[key] = {};
          });

          payload.forEach(id => {
            const item = items.find(item => (item.id === id));
            keys.forEach(key => {
              if (item[key]) {
                item[key].forEach(value => {
                  initialValues[key][value.id] = value;
                });
              }
            });
          });

          keys.forEach(key => {
            initialValues[key] = Object.keys(initialValues[key]).map(id => initialValues[key][id]);
          });

        }

        props.show(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, {
          onSubmit: (values) => {
            props.setLoading(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, true);
            let promises = [];
            if (name === 'attrAll') {
              items.forEach(item => {
                promises.push(MemoryApi.saveItem(service, {
                  id: item.id,
                  ...values
                }));
              });
            } else {
              payload.forEach(id => {
                promises.push(MemoryApi.saveItem(service, {
                  id: id,
                  ...values
                }));
              });
            }
            Promise
              .all(promises)
              .then(response => {
                this.loadItems(this.state.page);
              })
              .finally(response => {
                props.setLoading(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, false);
                props.hide(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL);
              });
          },
          initialValues: initialValues
        });
        break;
      case 'attrSingle':
        props.show(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, {
          onSubmit: (values) => {
            props.setLoading(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, true);
            const items = [{
              id: payload.id,
              source: payload.source,
              target: payload.target,
              ...values
            }]
            MemoryApi
              .saveItems(service, items)
              .then(response => {
                this.loadItems(this.state.page);
              })
              .finally(response => {
                props.setLoading(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, false);
                props.hide(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL);
              });
          },
          initialValues: payload
        });
        break;
      case 'import':
        props.show(TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL, {
          onSubmit: (values) => {
            MemoryApi.importItems(memory.id, values)
              .then(response => {
                if (response.data.saved) {
                  this.loadItems();
                }
                props.hide(TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL)
                props.reset(TRANSLATION_MEMORY_DETAIL_IMPORT_FORM)
              })
              .catch(error => {
                if (error.response.status === 400) {
                  this.props.stopSubmit(
                    TRANSLATION_MEMORY_DETAIL_IMPORT_FORM,
                    {_error: error.response.data.detail}
                  );
                }
              });

          }
        });
        break;
      case 'export':
        props.show(TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL, {
          onSubmit: (values) => {
            props.hide(TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL);
            if (values.fileFormat === 'tmx') {
              this.props.exportTmxRequest(
                memory.id,
                memory.name
              );
            } else {
              this.props.exportExcelRequest(
                memory.id,
                memory.name
              );
            }
          }
        });
        break;
      case 'clear':

        this.setState({
          modalBatchDeleteIsOpen: true,
        });

        // props.show(TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL, {
        //   onSubmit: (values) => {
        //     props.searchClearRequest({
        //       id: memory.id
        //     })
        //   },
        //   initialValues: {}
        // });
        break;

      default:
        props.show(TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL, {
          onSubmit: (values) => {
            this.props.searchUpdateRequest({
              id: memory.id,
              data: values
            });
          },
          initialValues: {
            name: memory.name,
            service: memory.service,
            client: memory.client,
            jobs: memory.jobs,
            keyword: memory.keyword,
            penalty_only: memory.penalty_only,
            exclude_penalty: memory.exclude_penalty,
          }
        });

    }

  };

  onRemove = (items) => {
    return MemoryApi.deleteItems(this.props.memory.service, items)
  }

  onSave = (mutations, force_merge) => {

    const service = this.props.memory.service;

    this.setState({
      isSaving: true,
      errors: {}
    }, () => {

      let errors = {};
      let operations = [];

      let saveItems = [];
      let savePromise;

      let deleteItems = [];
      let deletePromise = [];

      Object
        .keys(mutations.index)
        .forEach((key) => {
          if (!(mutations.removed && mutations.removed[key])) {

            let value = {
              ...mutations.data[key]
            };
            if (
              mutations.rejected &&
              mutations.rejected[key]
            ) {
              value.is_pending = true;
            } else if (
              mutations.committed &&
              mutations.committed[key]
            ) {
              value.is_pending = false;
            }
            saveItems.push(value);
          } else {
            deleteItems.push(key);
          }
        });

      // do in sequence to deliver one error at the time

      if (saveItems.length) {
        const normalized = normalize(saveItems, [memorySchema]);
        const _saveItems = Object.values(normalized.entities.memories);
        savePromise = MemoryApi.saveItems(service, _saveItems, force_merge);
      } else {
        savePromise = new Promise(function (resolve, reject) {
          resolve();
        });
      }

      savePromise
        .then(response => {
          const updateResponseErrors = response.data.errors;
          if (
            updateResponseErrors.length
          ) {
            errors.hasMerge = true;
            if (typeof errors.items === 'undefined') {
              errors.items = {};
            }
            if (typeof errors.merge === 'undefined') {
              errors.merge = {};
            }
            updateResponseErrors.forEach(item => {
              errors.items[item.id] = item;
              errors.merge[item.id] = true;
            });
          }

          if (
            !updateResponseErrors.length &&
            deleteItems.length
          ) {
            deletePromise = MemoryApi.deleteItems(service, deleteItems)
          } else {
            deletePromise = new Promise(function (resolve, reject) {
              resolve();
            });
          }

          return deletePromise;
        })
        .catch(error => {
          if (error) {
            errors.unknown = error;
          }
        })
        .finally(() => {
          if (Object.keys(errors).length) {
            this.setState({
              // isSaving: false,
              // let it spin until error message is dismissed (because it refreshes list otherwise)
              errors: errors
            });
          } else {
            this.loadItems(this.state.page,this.state.search.string);
          }
        });

    });

  };

  onDismissError = (errorKey) => {

    let errors = this.state.errors;

    if (errorKey === 'merge') {
      errors.merge = {};
      errors.hasMerge = false;
    }

    this.setState({
      key: Date.now,
      errors: errors,
      isSaving: false
    });

  };

  modalBatchDeleteOnDone = () => {
    this.setState({
      updated: Date.now(),
      modalBatchDeleteIsOpen: false,
      items: []
    });
    this.loadItems();
  };

  modalBatchDeleteOnCancel = () => {
    this.setState({
      modalBatchDeleteIsOpen: false,
    });
  };

  getLanguages = () => {
    let languages = {
      src: '',
      dst: ''
    };
    if (!this.state.isLoading) {
      const memory = this.props.memoryDenormalized;
      if (
        memory &&
        memory.service
      ) {
        languages.src = memory.service.src;
        languages.dst = memory.service.dst;
      }
    }
    return languages;
  };

  searchItems = (string) => {

    const isValid = !!string.length;

    this.setState({
      page: 1,
      search: {
        string: string,
        isValid: isValid
      }
    }, () => {
      this.loadItems(1, (isValid) ? string : '');
    });

  }

  gotToPage = (page) => {
    let string;
    if (this.state.search.isValid) {
      string = this.state.search.string;
    }
    this.loadItems(page, string);
  };

  render() {

    const state = this.state;
    const reRenderKey = state.updated + state.page;

    return (
      <React.Fragment>
        <TaPane size={'page'}>
          <TranslationMemoryDetail
            updated={reRenderKey}
            memory={this.props.memoryDenormalized || {}}
            languages={this.getLanguages()}
            page={state.page}
            search={state.search}
            items={state.items}
            errors={state.errors}
            totalItemCount={state.totalItemCount}
            isLoading={state.isLoading}
            isSaving={state.isSaving}
            onQuery={this.loadItems}
            onModal={this.triggerModal}
            onSave={this.onSave}
            onSearch={this.searchItems}
            goPage={this.gotToPage}
            onRemove={this.onRemove}
            onDismissError={this.onDismissError}
          />
        </TaPane>

        <TranslationMemoryBatchDeleteModal
            memoryId={this.props.params.id}
          isOpen={state.modalBatchDeleteIsOpen}
          onDone={this.modalBatchDeleteOnDone}
          onCancel={this.modalBatchDeleteOnCancel}
          jobs={this.props.memoryDenormalized && this.props.memoryDenormalized.jobs ? this.props.memoryDenormalized.jobs : []}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    memory: getCurrentTranslationMemoryDetail(state),
    memoryDenormalized: getSelectedTranslationMemoryItemNormalized(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    show,
    hide,
    reset,
    merge,
    stopSubmit,
    setLoading,
    searchUpdateRequest,
    searchClearRequest,
    exportExcelRequest,
    exportTmxRequest
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemoryDetailContainer);
