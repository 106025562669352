import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listRequest } from 'actions/customers';
import { getCustomers, getCustomerListCurrentPage, getCustomerListPageSize } from 'selectors/lists';
import { isCustomerListLoading } from 'selectors/loading';
import { getAEOptions } from 'selectors/options';
import { setFilter } from 'actions/filters'
import PureRenderComponent from 'components/core/PureRenderComponent';
import CustomerList from './presenter';


class CustomerListContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      ae: '',
      client: '',
      keyword: '',
    }
  }

  componentDidMount() {
    const params = { page: this.props.currentPage };
    this.props.listRequest(params);
  }

  handleKeywordChange = (keyword) => {
    this.setState({ keyword })
  }
  
  handleAEChange = (ae) => {
    console.log(ae)
    this.setState({ ae })
    this.props.listRequest({
      ae,
      page: 1,
      client: this.state.client,
      keyword: this.state.keyword,
    });
  }
  
  handleClientChange = (client) => {
    this.setState({ client })
    this.props.listRequest({
      client,
      page: 1,
      ae: this.state.ae,
      keyword: this.state.keyword,
    });
  }

  handlePageSelect = (page) => {
    const params = {
      keyword: this.state.keyword,
      ae: this.state.ae,
      client: this.state.client,
      page
    };
    this.props.listRequest(params);
  }

  handleSubmit = () => {
    const params = {
      keyword: this.state.keyword,
      ae: this.state.ae,
      client: this.state.client,
      page: 1
    };
    this.props.listRequest(params);
  }

  render() {
    return (
      <CustomerList
        {...this.props}
        ae={this.state.ae}
        client={this.state.client}
        keyword={this.state.keyword}
        handleAEChange={this.handleAEChange}
        handleClientChange={this.handleClientChange}
        handleKeywordChange={this.handleKeywordChange}
        handlePageSelect={this.handlePageSelect}
        handleSubmit={this.handleSubmit} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: getCustomers(state),
    isLoading: isCustomerListLoading(state),
    pageSize: getCustomerListPageSize(state),
    currentPage: getCustomerListCurrentPage(state),
    aeOptions: getAEOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest,
    setFilter
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerListContainer);