import React from 'react';
import * as PropTypes from "prop-types";
import {
    TaModal,
    TaModalContent,
    TaModalContentMargin,
    TaModalFooter,
    TaModalHeader
} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";

class TaMhtEditorConfirmActionModal extends React.Component {

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
    };

    render() {

        const {
            isOpen,
            title,
            message,
            onCancel,
            onConfirm
        } = this.props;

        return (
            <TaModal
                isOpen={isOpen}
                onCancel={() => {
                    if (typeof onCancel === "function") {
                        onCancel();
                    }
                }}
            >
                {(!!isOpen) && (
                    <TaMhtEditorConfirmActionModalContent
                        title={title}
                        message={message}
                        onCancel={() => {
                            if (typeof onCancel === "function") {
                                onCancel();
                            }
                        }}
                        onConfirm={() => {
                            if (typeof onConfirm === "function") {
                                onConfirm();
                            }
                        }}
                    />
                )}
            </TaModal>
        );
    }
}

class TaMhtEditorConfirmActionModalContent extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    render() {

        const {
            title,
            message,
            onConfirm,
            onCancel,
        } = this.props;

        return (
            <React.Fragment>
                <TaModalHeader
                    onCancel={onCancel}
                    label={'Confirm Action'}
                    warning
                />
                <TaModalContent>
                    <TaModalContentMargin>
                        <b>{title}</b>
                        <div>{message}</div>
                    </TaModalContentMargin>
                </TaModalContent>
                <TaModalFooter>
                    <TaButton
                        type="reset"
                        icon={'clear'}
                        label={'Cancel'}
                        onClick={() => onCancel()}
                    />
                    <div/>
                    <TaButton
                        type="submit"
                        icon={'check'}
                        label={'Proceed'}
                        onClick={() => {
                            this.setState({
                                isLoading: true
                            });
                            onConfirm();
                        }}
                    />
                </TaModalFooter>
            </React.Fragment>
        );
    }
}

export default TaMhtEditorConfirmActionModal;
