import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { initialize } from 'redux-form';
import {
    mhtJobRetrieveRequest,
    mhtInvoiceDownloadRequest,
    quoteSourcefileListRequest,
    mhtJobDeliveryRequest,
    mhtJobRedeliveryRequest,
    mhtJobMessageListRequest,
} from 'actions/jobs/managers';
import { 
    targetfileUploadRequest,
    targetfileDownloadRequest,
    targetfileRemoveRequest,
    quoteSourcefileDownloadRequest
} from 'actions/documents';
import { isMHTJobRetrieveLoading } from 'selectors/loading';
import { getJobValueById } from 'selectors/jobs';
import { getQuoteSourcefilesById } from 'selectors/lists';
import { SINGLE_FILE_UPLOAD_MODAL, MHT_INVOICE_DOWNLOAD_MODAL, MHT_INVOICE_DOWNLOAD_FORM } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import MHTJobDetail from './presenter';



class MHTJobDetailContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.mhtJobRetrieveRequest(this.props.id);
    this.props.quoteSourcefileListRequest(this.props.id);
  }

  showUploadModal = (sourceFileId) => {
    this.props.show(SINGLE_FILE_UPLOAD_MODAL, {
      title: 'Target File',
      handleUpload: (values) => this.handleUpload(sourceFileId, values),
    })
  }

  handleUpload = (sourceFileId, values) => {
    this.props.targetfileUploadRequest(this.props.id, sourceFileId, values)
  }

  showInvoiceDownloadModal = () => {
    this.props.show(MHT_INVOICE_DOWNLOAD_MODAL, {
      handleDownload: this.downloadInvoice
    });
    this.props.initialize(MHT_INVOICE_DOWNLOAD_FORM, {
      to: this.props.job.customer.to || this.props.job.customer.name,
      language: this.props.job.customer.language || "ja-JP"
    });
  }

  downloadInvoice = (values) => {
    this.props.mhtInvoiceDownloadRequest({
      id: this.props.id,
      language: values.language,
      to: values.to,
      jobNumber: this.props.job.number
    })
  }

  downloadSourceFile = (e, sourceFile) => {
    e.preventDefault()
    this.props.quoteSourcefileDownloadRequest(sourceFile)
  }
  downloadTargetFile = (e, targetFile) => {
    e.preventDefault()
    this.props.targetfileDownloadRequest(targetFile);
  }
  
  removeTargetFile = (quoteSourcefileId, targetFile) => {
    this.props.targetfileRemoveRequest(this.props.id, quoteSourcefileId, targetFile);
  }

  targetfileUploaded = () => {
    const uploaded = this.props.files.filter(f => f.targetFiles.length > 0)
    return uploaded.length > 0
  }

  isDeliveryable = () => {
    return this.props.job && this.props.job.status === "AE Validating"
  }

  isReDeliveryable = () => {
    return this.props.job && (
      this.props.job.status === "Invoicing Now" ||
      this.props.job.status === "Job Finished"
    )
  }

  handleDelivery = () => {
    this.props.mhtJobDeliveryRequest(this.props.id);
  }

  handlRedelivery = () => {
    this.props.mhtJobRedeliveryRequest(this.props.id);
  }

  render() {
    return (
      <MHTJobDetail
        {...this.props}
        handleDelivery={this.handleDelivery}
        handlRedelivery={this.handlRedelivery}
        showUploadModal={this.showUploadModal}
        downloadSourceFile={this.downloadSourceFile}
        downloadTargetFile={this.downloadTargetFile}
        removeTargetFile={this.removeTargetFile}
        showInvoiceDownloadModal={this.showInvoiceDownloadModal}
        targetfileUploaded={this.targetfileUploaded}
        isDeliveryable={this.isDeliveryable}
        isReDeliveryable={this.isReDeliveryable} />
    );
  }
}


const mapStateToProps = (state, props) => {
  const id = props.params.id;
  return {
    id,
    job: getJobValueById(state, id),
    isLoading: isMHTJobRetrieveLoading(state, id),
    files: getQuoteSourcefilesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    initialize,
    mhtJobDeliveryRequest,
    mhtJobRedeliveryRequest,
    mhtJobRetrieveRequest,
    mhtInvoiceDownloadRequest,
    targetfileUploadRequest,
    targetfileDownloadRequest,
    targetfileRemoveRequest,
    quoteSourcefileDownloadRequest,
    quoteSourcefileListRequest,
    mhtJobMessageListRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MHTJobDetailContainer);
