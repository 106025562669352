import React from 'react';
import * as PropTypes from "prop-types";
import {
    TaModal,
    TaModalContent,
    TaModalContentMargin,
    TaModalFooter,
    TaModalHeader
} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import VirtualizedSelect from "react-virtualized-select";
import {TaModalSteps} from "../../taUi/taModal/TaModalSteps";
import {TaFormFieldError} from "../../taUi/taFormField/taFormFieldError";
import MemoryApi from "../../../services/MemoryApi";

const composeJobOption = (job) => ({
    label: job.number,
    value: job.id
});

class TranslationMemoryBatchDeleteModal extends React.Component {

    static propTypes = {
        memoryId: PropTypes.number.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onDone: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor() {
        super();
        this.state = {
            selectedJobs: [],
            step: 1,
            isProcessing: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isOpen !== this.props.isOpen
        ) {
            this.setState({
                selectedJobs: this.props.jobs ? this.props.jobs.map(composeJobOption) : []
            });
        }

    }

    handleSelectedJobsChange = (upatedSelectedJobs) => {
        this.setState({
            selectedJobs: upatedSelectedJobs
        });
    }

    handleCancel = () => {
        if (!this.state.isProcessing) {
            this.setState({
                selectedJobs: [],
                step: 1,
                isProcessing: false,
                errorMessage: '',
            });
            this.props.onCancel();
        }
    }

    confirmStep = () => {
        if (!this.state.isProcessing) {
            if (this.state.step === 3) {
                this.setState({
                    isProcessing: true,
                    errorMessage: ''
                }, () => {
                    MemoryApi.searchClear(this.props.memoryId, this.state.selectedJobs.map(job => job.value))
                        .then(response => {
                            this.setState({
                                isProcessing: false,
                                step: 1
                            });
                            this.props.onDone();
                        }).catch((e) => {
                        this.setState({
                            isProcessing: false,
                            errorMessage: 'An error occurred'
                        });
                    });
                });
            } else {
                this.setState({
                    step: this.state.step + 1
                });
            }
        }
    };

    render() {

        const state = this.state;

        const {
            isOpen,
            jobs
        } = this.props;

        return (
            <TaModal
                isOpen={isOpen}
                onCancel={() => this.handleCancel()}
            >
                <TaModalHeader
                    label={'Batch Delete Translation Memory'}
                    warning={true}
                    onCancel={() => this.handleCancel()}
                />
                <TaModalSteps steps={['Confirm', 'Jobs', 'Delete']} activeStep={state.step}/>
                <TaModalContent scroll={'auto'}>
                    {
                        state.step === 1 ? (
                            <TaModalContentMargin>
                                <p><b>Are you sure you want to continue?</b><br/>
                                    This action will permanently delete translation memory across multiple jobs!</p>
                                <p>In the following steps, you will be asked to:<br/>
                                    &bull; Select the relevant jobs<br/>
                                    &bull; Confirm the deletion<br/>
                                </p>
                            </TaModalContentMargin>
                        ) : null
                    }
                    {state.step === 2 ? (
                        <TaModalContentMargin>
                            <p>
                                <b>Select jobs</b><br/>
                                The translation memory should be deleted for the following jobs:
                            </p>
                            {jobs ? (
                                <p>
                                    <VirtualizedSelect
                                        labelKey={'label'}
                                        valueKey={'value'}
                                        multi={true}
                                        simpleValue={false}
                                        filterOption={(option, filterString) => {
                                            return option.label && option.label.toLowerCase().indexOf(filterString.toLowerCase()) !== -1
                                        }}
                                        isLoading={false}
                                        value={state.selectedJobs}
                                        options={jobs.map(composeJobOption)}
                                        onChange={v => this.handleSelectedJobsChange(v)}
                                    />
                                    {state.selectedJobs.length === 0 ? (
                                        <TaFormFieldError>
                                            Please select at least one job
                                        </TaFormFieldError>
                                    ) : null}
                                </p>
                            ) : null}

                        </TaModalContentMargin>
                    ) : null
                    }
                    {state.step === 3 ? (
                        <TaModalContentMargin>
                            <p><b>Please confirm deletion</b><br/>
                                All {this.state.totalItemCount} translation units will be <b>permanently
                                    deleted</b>{' '}
                                from the translation memory of the following jobs:</p>
                            <p>
                                {state.selectedJobs.map(job => <span key={job.value}>&bull; {job.label}<br/></span>)}
                            </p>
                            {state.errorMessage ? (
                                <TaFormFieldError>
                                    {state.errorMessage}
                                </TaFormFieldError>
                            ) : null}
                        </TaModalContentMargin>
                    ) : null
                    }
                </TaModalContent>
                <TaModalFooter>
                    <TaButton
                        type="reset"
                        icon={'clear'}
                        label={'Cancel'}
                        onClick={() => this.handleCancel()}
                        disabled={state.isProcessing}
                    />
                    <TaButton
                        type="submit"
                        icon={(state.step === 3) ? 'delete_forever' : 'arrow_forward_ios'}
                        iconAlign={'right'}
                        label={(state.step === 3) ? (state.isProcessing) ? 'Deleting...' : 'Confirm & Delete' : 'Continue'}
                        onClick={() => this.confirmStep()}
                        isLoading={state.isProcessing}
                        disabled={state.step === 2 && jobs.length && state.selectedJobs.length === 0}
                    />
                </TaModalFooter>
            </TaModal>
        );
    }
}

export default TranslationMemoryBatchDeleteModal;